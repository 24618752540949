import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/global/Layout";
import { Services } from "../components/services/Services";
import SEO from "../components/global/SEO";

const ServicesPage = ({ data }) => {
  return (
    <Layout>
      <div className="flex items-center flex-col justify-center w-full p-4">
        <span className="block text-xl mt-20 mb-10">
          This page is currently under construction. Please, visit later.
        </span>
        <img
          className="mb-10"
          src="https://cdn.pixabay.com/photo/2017/06/20/08/12/maintenance-2422173_960_720.png"
          alt="Under construction"
        />
      </div>
    </Layout>
  );

  return (
    <Layout>
      <SEO />
      <Services data={data} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allStrapiService {
      edges {
        node {
          data {
            attributes {
              weight
              test
              shortDescription
              name
              homepageTitle
              displayOnHome
              description
              backgroundImageUrl
              slug
            }
          }
        }
      }
    }
  }
`;

export default ServicesPage;
